import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TypeaheadMatch } from 'ngx-bootstrap';
import { AlertAndNotificationsService } from 'src/app/core/services/alert-and-notifications.service';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ManageSkillsService } from 'src/app/core/services/manage-skills.service';
import { RequisitionSkill } from 'src/app/core/models/requisitionSkill';
declare var bootbox: any;

@Component({
    selector: 'app-add-edit-candidate-skill',
    templateUrl: './add-edit-candidate-skill.component.html',
    styleUrls: ['./add-edit-candidate-skill.component.css']
})
export class AddEditCandidateSkillComponent implements OnInit {

    @Input() userId;
    @Input() skills;
    @Input() skillType;
    @Input() context;
    @Input() mode;
    @Input() currentSkills;
    @Input() originalSkills;
    @Input() allExistingSkills;
    @Input() allExistingDegrees;
    @Input() allExistingFieldOfStudy;
    @Input() allExistingRoles;
    @Input() allExistingCertificates;
    @Input() allExistingJDActionWords;
    @Input() timePeriods;
    @Input() isViewOnly;
    @Output() saveCallback = new EventEmitter<any>();
    @Output() cancelCallback = new EventEmitter<any>();
    @Output() setOtherSkillsCallback = new EventEmitter<any>();
    @Output() setSkillsAsPerCategoryCallback = new EventEmitter<any>();

    skillHeading: any;
    skillsCopy: any;
    selected: any = undefined;
    searchResults: any = [];
    selectedSkillType: any;
    skillTypes: any = [];
    skillsTimePeriods: any = [];
    allSkills: any;
    skillsTypeAheadList: any = [];
    ranges: any = [];
    resetSkillsList: any = [];
    educationFieldOfStudy: any = [];
    experienceRoles: any = [];
    draggable: any;
    addNewSkill: any;
    addNewSkillObj: any;
    isEditSkills: boolean = false;
    viewSkillDetails: boolean = true;
    expandSkills: boolean;
    isRemoveSectionActive: boolean = false;

    searchCertificationText: any = '';
    searchCertificates: any;
    searchEducationText: any = '';
    searchEducations: any;
    searchExperienceText: any = '';
    searchExperiences: any;
    searchSkillText: any = '';
    searchSkills: any;
    sections: any = [[new RequisitionSkill('')]];
    public certificationsObservable: Observable<any[]>;
    public educationObservable: Observable<any[]>;
    public experienceObservable: Observable<any[]>;
    public skillsObservable:  Observable<any[]>;

    constructor(
        private alertsAndNotificationsService: AlertAndNotificationsService,
        private skillsService: ManageSkillsService
    ) {
    }

    ngOnChanges(changes) {
        if (changes && changes.allExistingSkills && !changes.allExistingSkills.firstChange && changes.allExistingSkills.currentValue && changes.allExistingSkills.previousValue) {
            if (changes.allExistingSkills.currentValue.length != changes.allExistingSkills.previousValue) {
                this.setAllExistingSkills(changes.allExistingSkills.currentValue);
            }
        }

        if (changes && changes.skills && !changes.skills.firstChange && changes.skills.currentValue && changes.skills.previousValue) {
            if (changes.allExistingSkills && (changes.allExistingSkills.currentValue.length != changes.allExistingSkills.previousValue)) {
                this.skillsCopy = this.skills.slice(0,);
            }
        }
    }

    ngOnInit() {
        this.skills = this.skills ? this.skills : [];
        this.skills.forEach(skill => {
            skill.atleastOrMinOrMax = "atleast",
                skill.plusTo = ""
        });
        this.skillHeading = this._getSkillsHeading();
        this.skillsCopy = angular.copy(this.skills);
        // if (this.mode && this.mode === 'edit') {
        //     this.isEditSkills = true;
        // }
        this._setAllSkills();
        this.selectedSkillType = "education";
        this.skillTypes = [
            { name: "Education", value: "education" },
            { name: "Experience", value: "experience" },
            { name: "Technical Skills", value: "technical" },
            { name: "Operational Skills", value: "operational" },
            { name: "Soft Skills", value: "soft" },
            { name: "Other", value: "other" },
            { name: "Certifications", value: "certifications" },
        ];
        let skills = {
            education: [],
            experience: [],
            technical: [],
            operational: [],
            soft: [],
            other: [],
            certifications: []
        };
        this.currentSkills = angular.copy(skills);
        this.originalSkills = angular.copy(skills);

        this._massageSkillsData();
        this.ranges = [
            { name: "", value: "" },
            { name: "Atleast", value: "atleast" },
            { name: "Min", value: "min" },
            { name: "Max", value: "max" }
        ];
        this.resetSkillsList = [...this.skills];
        this.setSkillsObservable();
        this.isEditSkills = true; // New Change
    }

    setSkillsObservable() {
        this.certificationsObservable = Observable.create((observer: any) => {
            // Runs on every search 
            observer.next(this.searchCertificationText);
        }).pipe(
            mergeMap((token: string) => this.getSkillsAsObservable(token))
        );

        this.educationObservable = Observable.create((observer: any) => {
            // Runs on every search 
            observer.next(this.searchEducationText);
        }).pipe(
            mergeMap((token: string) => this.getSkillsAsObservable(token))
        );

        this.experienceObservable = Observable.create((observer: any) => {
            // Runs on every search 
            observer.next(this.searchExperienceText);
        }).pipe(
            mergeMap((token: string) => this.getSkillsAsObservable(token))
        );

        this.skillsObservable = Observable.create((observer: any) => {
            // Runs on every search 
            observer.next(this.searchSkillText);
        }).pipe(
            mergeMap((token: string) => this.getSkillsAsObservable(token))
        );
    }

    onSearchCertificationChange(searchCertificationText: string): void {
        this.searchCertificationText = searchCertificationText;
    }

    onSearchEducationChange(searchEducationText: string): void {
        this.searchEducationText = searchEducationText;
    }

    onSearchExperienceChange(searchExperienceText: string): void {
        this.searchExperienceText = searchExperienceText;
    }

    onSearchSkillChange(searchSkillText: string): void {
        this.searchSkillText = searchSkillText;
    }

    _setTypeAheadList(type) {
        switch (type) {
            case 'skills':
                return this.allSkills.allExistingSkills;
            default:
                return this.allSkills.other;
        }
    }

    _getSkillsHeading() {
        switch (this.skillType) {
            case "education":
                return "Education";
            case "experience":
                return "Experience";
            case "technical":
                return "Technical Skills";
            case "operational":
                return "Operational Skills";
            case "soft":
                return "Soft Skills";
            case "other":
                return "Other";
            case "certification":
                return "Certifications"
            default:
                return "Technical Skills";
        }
    }

    _setAllSkills() {
        this.allSkills = {
            education: [],
            department: [],
            experience: [],
            certifications: []
        };

        if (this.allExistingSkills && this.allExistingSkills.length > 0) {
            this.allExistingSkills.forEach(skill => {
                skill.competency = skill.skill;
            });
            this.setAllExistingSkills(this.allExistingSkills);
        } else {
            this.setAllExistingSkills(null);
        }
    }


    _massageSkillsData() {
        angular.forEach(this.skillTypes, (type, key) => {
            angular.forEach(this.allSkills[type.value], (skill, key) => {
                this._setSkillDisplayValue(skill);
            });
            angular.forEach(this.skills[type.value], (skill, key) => {
                this._setSkillDisplayValue(skill);
            });
            angular.forEach(this.currentSkills[type.value], (skill, key) => {
                this._setSkillDisplayValue(skill);
            });
            angular.forEach(this.originalSkills[type.value], (skill, key) => {
                this._setSkillDisplayValue(skill);
            });
        });
    }

    _setSkillDisplayValue(skill) {
        var skillDisplayValue = "";
        skillDisplayValue = skillDisplayValue + skill.name;
        skill.skillDisplayValue = skillDisplayValue;
    }

    searchSkillHasAndOrNil(text: string) {
        // Check if the string contains 'AND', 'OR', or 'NIL' (case-insensitive)
        if (!/\b(and|or|nil)\b/i.test(text)) {
            return { allString: [], lastString: null };
        }
    
        // Split the text based on 'AND', 'OR', or 'NIL' (case-insensitive)
        const allString = text.split(/\b(?:and|or|nil)\b/i).map(part => part.trim());
        const lastString = allString.length > 0 ? allString[allString.length - 1] : null;
    
        return { allString, lastString };
    }    

    setSearchStringWithTimePeriods(searchText) {
        // return results with time periods
        searchText = searchText.toLowerCase();
        if (this._searchStringHasYearsInfoButNoSkill(searchText, 'skill')) {
            return this._getYearsForSearchString(searchText, 'skill');
        } else {
            return [];
        }
    }

    setSearchStringWithSkills(searchText) {
        // return results with skills
        let searchResults = [];
        let timePeriodObj = this.timePeriods.filter(timePeriod => searchText.toLowerCase().includes(timePeriod.name.toLowerCase()))
    .sort((a, b) => b.name.length - a.name.length)[0];

        let existingSkillName = timePeriodObj ? searchText.replace(new RegExp(`^${timePeriodObj.name}`, 'i'), '').trim() : searchText;
        
        let preText = timePeriodObj ? timePeriodObj.name : "";
        let isSearchStringHasYears = timePeriodObj ? true : false;
        let actualSearchText = existingSkillName;

        if (!isSearchStringHasYears) {
            actualSearchText = searchText;
        }

        let validSkills = this.allExistingSkills.filter((skill) => {
            return skill && skill.skill && skill.skill.toLowerCase().includes(actualSearchText);
        });

        if (isSearchStringHasYears && validSkills.length > 0) {
            angular.forEach(validSkills, (skill, key) => {
                let addMonthsText = (timePeriodObj &&  timePeriodObj.value.months > 0) ? this.convertMonthsToYearsText(timePeriodObj.value, skill.skill, (preText + skill.skill)) : '';
                searchResults.push({ displayName: preText + skill.skill, convertedDisplayName:addMonthsText, name: skill.skill, experience: timePeriodObj.value, mandatory: false, favorite: false, id: skill.id, skill: skill });
            });
        } else if (validSkills.length > 0) {
            angular.forEach(validSkills, (skill, key) => {
                searchResults.push({ displayName: skill.skill, name: skill.skill, experience: skill.experience, mandatory: false, favorite: false, id: skill.id, skill: skill });
            });
        }
        return searchResults;
    }

    setSearchSkillWithTimePeriodsAndSkills(searchText) {
        // return results with time periods and skills
        let searchResults = [];
        searchText = searchText.toLowerCase();
        searchResults = this.setSearchStringWithTimePeriods(searchText);
        if(searchResults.length > 0) {
            return searchResults;
        } else {
            searchResults = this.setSearchStringWithSkills(searchText);
            return searchResults;
        }
    }

    endsWithLogicalWord(text: string): boolean {
        // Check if the text ends with 'AND', 'OR', or 'NIL' (case-insensitive)
        return /\b(?:AND|OR|NIL)\s*$/i.test(text);
    }
    
    checkIfSearchStringHasBrackets(searchText: string, type: 'startWith' | 'endWith'): boolean {
        // Check if the search string starts or ends with a bracket
        return type === 'startWith' ? searchText.startsWith('(') : searchText.endsWith(')');
    }
    
    setSearchStringWithBrackets(searchText: string, searchResults: any[], preText): any[] {
        const searchSkillHasAndOr = this.searchSkillHasAndOrNil(searchText);
    
        if (searchSkillHasAndOr.lastString) {
            if (searchSkillHasAndOr.lastString === '(') {
                // Recursively handle cases where the last string is an opening bracket
                return searchResults = this.setSearchStringWithBrackets(searchSkillHasAndOr.lastString, searchResults, searchText);
            } else {
                // Handle search results based on lastString
                let tempSearchText = searchSkillHasAndOr.lastString.startsWith("(") ? searchSkillHasAndOr.lastString.slice(1) : searchSkillHasAndOr.lastString;
                searchResults = this.setSearchSkillWithTimePeriodsAndSkills(tempSearchText);
                const lastIndex = searchText.lastIndexOf(searchSkillHasAndOr.lastString);
                const preText = lastIndex !== -1 ? searchText.slice(0, lastIndex).trim() : "";
                
                searchResults.forEach(result => {
                    if(searchSkillHasAndOr.lastString.startsWith("(")) {
                        result.displayName = preText + ' (' + result.displayName;
                    } else {
                        result.displayName = `${preText} ${result.displayName}`;
                    }
                });
    
                if (
                    (searchResults.length === 0 || searchResults.length === 1) &&
                    !this.endsWithLogicalWord(searchText) &&
                    searchText.endsWith(" ")
                ) {
                    let displayName = "";
                    if (preText) {
                        displayName = searchText.includes(preText) ? searchText : preText;
                    } else {
                        displayName = '(' + searchText;
                    }
                    searchResults.push({ displayName: displayName + 'AND', name: 'AND' });
                    searchResults.push({ displayName: displayName + 'OR', name: 'OR' });
                    searchResults.push({ displayName: displayName + 'NIL', name: 'NIL' });
                }
                return searchResults;
            }
        } else {
            // Process the case where no logical operator exists in the lastString
            let tempSearchText = searchText.startsWith("(") ? searchText.slice(1) : searchText;
            searchResults = this.setSearchStringWithTimePeriods(tempSearchText);
    
            if (searchResults.length > 0) {
                searchResults.forEach(result => {
                    result.displayName = preText ? preText + result.displayName : '(' + result.displayName;
                });
            } else {
                searchResults = this.setSearchStringWithSkills(tempSearchText);
    
                if (
                    (searchResults.length === 0 || searchResults.length === 1) &&
                    !this.endsWithLogicalWord(tempSearchText) &&
                    tempSearchText.endsWith(" ")
                ) {
                    let displayName = preText ? preText + tempSearchText : '(' + tempSearchText;
                    searchResults.push({ displayName: displayName + 'AND', name: 'AND' });
                    searchResults.push({ displayName: displayName + 'OR', name: 'OR' });
                    searchResults.push({ displayName: displayName + 'NIL', name: 'NIL' });
                } else {
                    searchResults.forEach(result => {
                        result.displayName = preText ? preText + result.displayName : '(' + result.displayName;
                    });
                }
            }
            return searchResults;
        }
    }
    
    getSkill(searchText: string): any[] {
        let searchResults: any[] = [];
        const checkIfStartsWithBrackets = this.checkIfSearchStringHasBrackets(searchText, 'startWith');
    
        if (checkIfStartsWithBrackets) {
            // return results if search string has open bracket
            searchResults = this.setSearchStringWithBrackets(searchText, [], null);
        } else {
            // return results if search string has no bracket
            const searchSkillHasAndOr = this.searchSkillHasAndOrNil(searchText);
    
            if (searchSkillHasAndOr.lastString) {
                searchResults = this.setSearchSkillWithTimePeriodsAndSkills(searchSkillHasAndOr.lastString);
                const lastIndex = searchText.lastIndexOf(searchSkillHasAndOr.lastString);
                const preText = lastIndex !== -1 ? searchText.slice(0, lastIndex).trim() : "";
    
                searchResults.forEach(result => {
                    result.displayName = `${preText} ${result.displayName}`;
                });
    
                if (
                    (searchResults.length === 0 || searchResults.length === 1) &&
                    !this.endsWithLogicalWord(searchText) &&
                    searchText.endsWith(" ")
                ) {
                    searchResults.push({ displayName: searchText + 'AND', name: 'AND' });
                    searchResults.push({ displayName: searchText + 'OR', name: 'OR' });
                    searchResults.push({ displayName: searchText + 'NIL', name: 'NIL' });
                }
            } else {
                const tempSearchText = searchText.trim();
                searchResults = this.setSearchStringWithTimePeriods(tempSearchText);
    
                if (searchResults.length === 0) {
                    searchResults = this.setSearchStringWithSkills(tempSearchText);
    
                    if (
                        (searchResults.length === 0 || searchResults.length === 1) &&
                        !this.endsWithLogicalWord(tempSearchText) &&
                        tempSearchText.endsWith(" ")
                    ) {
                        searchResults.push({ displayName: tempSearchText + 'AND', name: 'AND' });
                        searchResults.push({ displayName: tempSearchText + 'OR', name: 'OR' });
                        searchResults.push({ displayName: tempSearchText + 'NIL', name: 'NIL' });
                    }
                }
            }
        }
        return searchResults;
    }

    convertMonthsToYearsText(periodValue, skill, displayName) {
        const wordToNumber = {
            "one": 1,
            "two": 2,
            "three": 3,
            "four": 4,
            "five": 5,
            "six": 6,
            "seven": 7,
            "eight": 8,
            "nine": 9,
            "ten": 10,
            "eleven": 11,
            "twelve": 12
        };
    
        // Regex to capture numeric or word-based months, with optional 'plus'/'+' or "At least/Minimum/Maximum"
        let monthsPattern = /(?:Atleast|Minimum|Maximum)?\s*(\d+|one|two|three|four|five|six|seven|eight|nine|ten|eleven|twelve)\s*(?:\+|plus)?\s*month(?:s)?/i;
    
        let match = displayName.match(monthsPattern);
        
        if (match) {
            let monthsString = match[1].toLowerCase();
            let months = isNaN(monthsString) ? wordToNumber[monthsString] : parseInt(monthsString);  // Convert word to number or use the numeric value
    
            let prefix = '';
            let suffix = '';
            
            // Check for "Atleast", "Minimum", "Maximum" and append them to the prefix
            if (/Atleast/i.test(displayName)) {
                prefix = 'Atleast ';
            } else if (/Minimum/i.test(displayName)) {
                prefix = 'Minimum ';
            } else if (/Maximum/i.test(displayName)) {
                prefix = 'Maximum ';
            }
    
            // Adjust for "plus" or "+" indicating additional months and update suffix
            if (match[0].includes('plus') || match[0].includes('+')) {
                suffix = ' +'; // Add the plus suffix
            }
    
            let years = (months / 12).toFixed(1);  // Convert months to years and round to 1 decimal place
    
            // Extract the skill from the displayName by removing the matched time period
            let skillName = displayName.replace(match[0], '').trim();
    
            return `${prefix}${years}${suffix} years ${skillName}`;
        } else {
            return '';
        }
    }

    onSkillSelect(e: TypeaheadMatch): void {
        this._addSkill(e);
        this.addNewSkillObj = e.item;
    }

    _addSkill(selectedSkill) {
        if (selectedSkill.id !== undefined) {
            this._setSkillDisplayValue(selectedSkill);
            this.selected = undefined;
        }
    }

    setFocusToNextElement(type, inputEvent, isLastElement) {
        let baseId;
        let currentIndex;
        if(type === 'onAddSkill') {
            baseId = (inputEvent === 'skillsInput') ? (inputEvent + this.skillType) : inputEvent;
            currentIndex = this.skills.length - 1;
        } else {
            inputEvent.blur();
            baseId = inputEvent.id.split('-')[0];
            currentIndex = parseInt(inputEvent.id.split('-')[1]);
        }
        let nextIndex = currentIndex + 1;
        let nextId = `${baseId}-${!isLastElement ? nextIndex : (this.skills.length - 1)}`;
        let nextElement = document.getElementById(nextId);
        nextElement.focus();
    }

    checkAndAddNewSkill(type, inputEvent, inputText, skill) {
        let checkIfEmptyInputFieldExists = this.skills.filter(skill => (!skill.displayName || skill.displayName == ''));
        if(checkIfEmptyInputFieldExists && checkIfEmptyInputFieldExists.length > 0) {
            setTimeout(() => {
                this.setFocusToNextElement(type, inputEvent, true);
            }, 100);
            if(type === 'onAddSkill') {
                this.displayEmptySkillMessage();
            }
        } else {
            if(this.checkIfSkillExists(skill, inputText)) {
                this.onAddSkill(type, skill, inputText, inputEvent);
            } else {
                if (type === 'onAddSkill') {
                    this.skills.push(new RequisitionSkill(''));
                } else {
                    this.displayEmptySkillMessage();
                }
            }
            
        }
    };

    checkIfSkillExists(skill, inputText) {
        if(this.skillType != 'education' && this.skillType != 'certification') {
            let timePeriodObj = this.timePeriods.filter(timePeriod => inputText.includes(timePeriod.name)).sort((a, b) => b.name.length - a.name.length)[0];
            let existingSkillName = timePeriodObj ? inputText.replace(timePeriodObj.name, '').trim() : inputText;
            return (existingSkillName && existingSkillName != '') ? true : false;
        } else {
            return true;
        }
    }

    newSkill(inputText: string, type: string, inputEvent: any, skill) {
        if (inputText && inputText !== '') {
            if (skill.typeAheadSelected && (skill.oldDisplaySkillName != skill.displayName)) {
                this.expandSkills = true;
                skill.typeAheadSelected = false;
                skill.oldDisplaySkillName = skill.displayName.slice();
            } else {
                this.expandSkills = true;
                this.checkAndAddNewSkill(type, inputEvent, inputText, skill);
            }
        } else {
            if (type === 'onEnter') {
                this.displayEmptySkillMessage();
            } else {
                this.expandSkills = true; 
                this.checkAndAddNewSkill(type, inputEvent, inputText, skill);
            }
        }
    }    

    displayEmptySkillMessage() {
        if (this.skillType === 'technical' || this.skillType === 'operational' || this.skillType === 'soft' || this.skillType === 'other') {
            this.alertsAndNotificationsService.showAlert('Skill Empty', "Enter Skill to Add", 'warning');
        } else {
            const title = this.skillType === 'education' ? 'Education' : this.skillType === 'experience' ? 'Experience' : 'Certification';
            this.alertsAndNotificationsService.showAlert(`${title} Empty`, `Enter ${title} to Add`, 'warning');
        }
    }

    _setNewItem(newItem: any) {
        return newItem && newItem.id ? {
            ...newItem,
            competency: newItem.skill || newItem.name
        } : {
            id: null,
            name: this.addNewSkill,
            atleastOrMinOrMax: '',
            competency: this.addNewSkill,
            mandatory: false,
            favorite: false,
            experience: { years: 0, months: 0 }
        };
    }

    setSkillsCopy(skills) {
        this.skillsCopy = skills.slice(0,);
    }

    onAddSkill(type, skill, inputText, inputEvent) {
        if (this.skillType === 'technical' || this.skillType === 'operational' || this.skillType === 'soft' || this.skillType === 'other') {
            let timePeriodObj = this.timePeriods.filter(timePeriod => (inputText.length > timePeriod.name.length) && (inputText.includes(timePeriod.name))).sort((a, b) => b.name.length - a.name.length)[0];
            // Check if input text has any of existing skills
            let existingSkillName = timePeriodObj ? inputText.replace(timePeriodObj.name, '').trim() : inputText;
            let existingSkill = this.allExistingSkills.filter(skill => 
                existingSkillName.toLowerCase() === skill.skill.toLowerCase()
            );

            if(!existingSkill || existingSkill.length === 0) {
                let newSkill = this.skills.filter(skill => skill.displayName === inputText)[0];
                newSkill.skill = {skill: existingSkillName};
                this.skills.push(new RequisitionSkill(''));
                setTimeout(() => {
                    this.setFocusToNextElement(type, inputEvent, false);
                }, 100);
            } else {
                if(existingSkill[0].category.toLowerCase() != this.skillType) {
                    let newSkill = this.skills.filter(skill => skill.displayName === inputText);
                    this.skills.push(new RequisitionSkill(''));
                    setTimeout(() => {
                        this.setFocusToNextElement(type, inputEvent, false);
                    }, 100);
                } else {
                    this.skills.push(new RequisitionSkill(''));
                    setTimeout(() => {
                        this.setFocusToNextElement(type, inputEvent, false);
                    }, 100);
                }
            }
            if(this.skills.length === 0) {
                this.skills.push(new RequisitionSkill(''));
            }
        } else {
            this.skills.push(new RequisitionSkill(''));
        }
    }

    onSkillUpdate(skillToUpdate) {
        this._setSkillDisplayValue(skillToUpdate);
        var skillIndex = _.findIndex(this.skills[this.selectedSkillType], (skill: any) => {
            return skillToUpdate.id === skill.id && skillToUpdate.skillDisplayValue === skill.skillDisplayValue;
        });
        this.skills[this.selectedSkillType].splice(skillIndex, 1, skillToUpdate);
    }

    onDeleteSkill(skill) {
        this._deleteSkill(skill);
        if (this.allSkills.allExistingSkills) {
            this.allSkills.allExistingSkills = this.allSkills.allExistingSkills.push(skill);
        }
    }

    _deleteSkill(skillToDelete) {
        _.remove(this.skills[this.selectedSkillType], (skill: any) => {
            return skillToDelete.id == skill.id && skillToDelete.skillDisplayValue == skill.skillDisplayValue;
        });
    }

    onResetSkillsClick(resetType) {
        var message = "";
        if (resetType == "previous") {
            message = "Going back to Requisition Detail of version saved previously. Are you sure to continue?";
        } else if (resetType == 'original') {
            message = "Going back to Requisition Detail of version saved when Requisition was ‘Activated’. Are you sure to continue?";
        }
        bootbox.confirm({
            closeButton: false,
            title: "<div class='alert alert-warning' style='margin-bottom: 0px;'><i class='fas fa-exclamation-triangle fa-fw fa-lg'></i><strong>Warning!</strong></div>",
            message: message,
            buttons: {
                confirm: {
                    label: 'Yes',
                    className: 'btn-success'
                },
                cancel: {
                    label: 'Cancel',
                    className: 'btn-danger'
                }
            },
            callback: (result) => {
                if (result) {
                    this._resetSkill(resetType);
                }
            }
        });
    }

    _resetSkill(resetType) {
        if (resetType == "previous") {
            this.skills = angular.copy(this.currentSkills);
        } else if (resetType == "original") {
            this.skills = angular.copy(this.originalSkills);
        }
    }

    toggleDetailsView() {
        this.viewSkillDetails = !this.viewSkillDetails;
    }

    enter() {
        console.log(this.selected);
        var selectedSkill = { id: 10, name: this.selected, mandatory: false, favorite: false, experience: { years: 0, months: 0 } };
        this._setSkillDisplayValue(selectedSkill);
        this.skills[this.selectedSkillType].push(selectedSkill);
        this.selected = undefined;
    }

    setAllExistingSkills(allSkills) {
        let existingSkillsIds = this.skills ? this.skills.map(skill => { return skill.id }) : [];
        if (this.allExistingSkills) {
            this.allExistingSkills = this.allExistingSkills.filter(skill => {
                if (!existingSkillsIds.includes(skill.id)) {
                    return true;
                }
            });
        }
        if (allSkills) {
            this.allSkills.allExistingSkills = [...this.allExistingSkills];
        } else {
            this.allSkills.allExistingSkills = [];
        }
    }

    editSkills() {
        this.isEditSkills = !this.isEditSkills;
        if (this.skillType === 'certification') {
            this.skills = this.skills.filter(skill => skill.displayName && skill.displayName != '');
            this.skills.forEach(skill => {
                skill = this.setCertificate(skill);
            });
        } else if (this.skillType === 'education') {
            this.skills = this.skills.filter(skill => skill.displayName && skill.displayName != '');
            this.skills.forEach(skill => {
                skill = this.setEducation(skill);
            });
        } else if (this.skillType === 'experience') {
            this.skills = this.skills.filter(skill => skill.displayName && skill.displayName != '');
            this.skills.forEach(skill => {
                skill = this.setExperience(skill);
            });
        } else {
            this.skills = this.skills.filter(skill => skill.displayName && skill.displayName != '');
            this.skills.forEach(skill => {
                skill = this.setSkills(skill);
            });
        }

        if (this.skillsCopy.length === this.skills.length) {
            this.resetSkillsList = [...this.skills];
        } else {
            const deletedSkills = this.skillsCopy.filter(element =>
                !this.skills.some(skill => (skill.id && skill.id === element.id) || (skill.skill === element.skill))
            );
            if (this.skillType != 'certification' && this.skillType != 'education' && this.skillType != 'experience') {
                this.allExistingSkills = [...this.allExistingSkills, ...deletedSkills];
            }
            this.resetSkillsList = [...this.skills];
        }

        if (this.skillType != 'certification' && this.skillType != 'education' && this.skillType != 'experience') {
            this.setAllExistingSkills(this.allExistingSkills);
        }

        if (this.saveCallback) {
            this.saveCallback.emit(this.allExistingSkills);
        }

    }

    deleteSkill(index) {
        this.skills.splice(index, 1);
    }

    onCandidateSkillUpdate(skillIndex, skillToUpdate, skillPriority) {
        if (skillPriority == 'mandatory' && skillToUpdate.favorite == true) {
            skillToUpdate.favorite = false;
        } else if (skillPriority == 'favorite' && skillToUpdate.mandatory == false) {
            skillToUpdate.favorite = true;
        }

        this.skills.splice(skillIndex, 1, skillToUpdate);
    }

    resetSkills() {
        this.skills = [...this.resetSkillsList];
        this.editSkills();
    }

    updateMandatory(skill) {
        skill.mandatory = !skill.mandatory;
        if (skill.mandatory) {
            skill.niceToHave = false;
            skill.preferred = false;
        }
    }

    updateNiceToHave(skill) {
        skill.niceToHave = !skill.niceToHave;
        if (skill.niceToHave) {
            skill.mandatory = false;
            skill.preferred = false;
        }
    }

    updatePreferred(skill) {
        skill.preferred = !skill.preferred;
        if (skill.preferred) {
            skill.mandatory = false;
            skill.niceToHave = false;
        }
    }

    onEditSkill() {
        this.isEditSkills = !this.isEditSkills;
        this.resetSkillsList = [...this.skills];
    }

    onRequisitionSkillSelect(e: TypeaheadMatch, skill): void {
        skill.skill = e.item.skill ? e.item.skill : null;
        skill.displayName = e.item.displayName ? e.item.displayName : e.item.name;
    }
    
    escapeRegExp(string: string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // Escape special characters
    }

    getSkillsAsObservable(token: string): Observable<any> {
        const escapedToken = this.escapeRegExp(token);
        const query = new RegExp(escapedToken, 'i');
        if (this.skillType === 'education') {
            this.searchEducations = [];
            this.searchEducations = this.getEducationTypeAhead(token);
            return of(
                this.searchEducations.filter((state: any) => {
                    return query.test(state.name);
                })
            );
        } else if (this.skillType === 'experience') {
            this.searchExperiences = [];
            this.searchExperiences = this.getExperienceTypeAhead(token);
            return of(
                this.searchExperiences.filter((state: any) => {
                    return query.test(state.name);
                })
            );
        } else if (this.skillType === 'certification') {
            this.searchCertificates = [];
            this.searchCertificates = this.getCertificationTypeAhead(token);
            return of(
                this.searchCertificates.filter((state: any) => {
                    return query.test(state.name);
                })
            );
        } else {
            this.searchSkills = [];
            this.searchSkills = this.getSkill(token);
            return of(
                this.searchSkills.filter((state: any) => {
                    return query.test(state.displayName);
                })
            );
        }

    }

    getCertificationTypeAhead(searchText) {
        let searchResults = [];
        searchText = searchText.toLowerCase();

        if (this.searchStringHasCertification(searchText)) {
            return this.getCertificatesForSearchString(searchText);
        }

        let searchCertificateObj;
        let preText = "";
        let isSearchStringHasCertificates = false;
        let actualSearchText = "";
        this.allExistingCertificates.forEach(certificate => {
            if (searchText.includes(certificate.group.toLowerCase())) {
                isSearchStringHasCertificates = true;
                preText = certificate.group;
                actualSearchText = "";
                actualSearchText = searchText.replace(preText.toLowerCase(), "");
                if (actualSearchText.includes(" in ")) {
                    actualSearchText = actualSearchText.replace(" in ", "");
                } else if (actualSearchText.includes(" in")) {
                    actualSearchText = actualSearchText.replace(" in", "");
                }
                searchCertificateObj = _.cloneDeep(certificate);
            }
        });

        if (!isSearchStringHasCertificates) {
            actualSearchText = searchText;
        }

        let validSkills = this.allExistingCertificates.filter((certificate) => {
            return certificate.name.toLowerCase().includes(actualSearchText);
        });

        if (isSearchStringHasCertificates && validSkills.length > 0) {
            validSkills.forEach(certificate => {
                searchResults.push(
                    {
                        displayName: searchCertificateObj.inName + certificate.name,
                        name: searchCertificateObj.inName + certificate.name,
                        experience: 0,
                        mandatory: false,
                        favorite: false,
                        id: certificate.id,
                        degreeName: searchCertificateObj.group,
                        fieldOfStudy: certificate.name
                    });
            });
        } else {
            validSkills.forEach(certificate => {
                searchResults.push(
                    {
                        displayName: certificate.name,
                        name: certificate.name,
                        experience: 0,
                        mandatory: false,
                        favorite: false,
                        id: certificate.id,
                        degreeName: "",
                        fieldOfStudy: certificate.name
                    });
            });
        }
        return searchResults;
    }

    searchStringHasCertification(searchText) {
        let certificatesResult = this.allExistingCertificates.filter((certificate) => {
            return certificate.group.toLowerCase().includes(searchText);
        });
        return certificatesResult.length > 0;
    }

    getCertificatesForSearchString(searchText) {
        let certificatesResult = this.allExistingCertificates.filter((certificate) => {
            return certificate.group.toLowerCase().includes(searchText);
        });
        let certificateObjectArray = [];
        certificatesResult.forEach(certificate => {
            certificateObjectArray.push({ displayName: certificate.inName, name: certificate.inName });
        })
        return certificateObjectArray;
    }

    setCertificate(certificate) {
        if (certificate.displayName.includes(" in ")) {
            let degreesAndFieldOfStudy = certificate.displayName.split(" in ");
            certificate.degreeName = degreesAndFieldOfStudy[0] ? degreesAndFieldOfStudy[0] : "";
            certificate.fieldOfStudy = degreesAndFieldOfStudy[1] ? degreesAndFieldOfStudy[1] : "";
        } else if (certificate.displayName.includes(" in")) {
            let degreesAndFieldOfStudy = certificate.displayName.split(" in");
            certificate.degreeName = degreesAndFieldOfStudy[0] ? degreesAndFieldOfStudy[0] : "";
            certificate.fieldOfStudy = "";
        } else {
            certificate.degreeName = certificate.displayName;
            certificate.fieldOfStudy = "";
        }
    }


    _searchStringHasDegreesInfoButNoFieldOfStudy(searchText) {
        let degreesResult = this.allExistingDegrees.filter((degree) => {
            return degree.degreeName.toLowerCase().includes(searchText);
        });
        return degreesResult.length > 0;
    }

    _getDegreesForSearchString(searchText) {
        let degreesResult = this.allExistingDegrees.filter((degree) => {
            return degree.degreeName.toLowerCase().includes(searchText);
        });
        let degreesObjectArray = [];
        degreesResult.forEach(degree => {
            degreesObjectArray.push({ displayName: degree.name, name: degree.name });
        })
        return degreesObjectArray;
    }

    setEducationFieldOfStudy() {
        let updatedFields = [];
    
        this.allExistingFieldOfStudy.forEach(fieldOfStudy => {
            const variations = [
                { suffix: ''},
                { suffix: ' or equivalent' },
                { suffix: ' or equivalent experience' },
                { suffix: ' or equivalent experience in related field' }
            ];
    
            variations.forEach(variation => {
                updatedFields.push({
                    ...fieldOfStudy,
                    id: `${fieldOfStudy.id}${variation.suffix}`,
                    competency: `${fieldOfStudy.fieldOfStudy}${variation.suffix}`,
                    fieldOfStudy: `${fieldOfStudy.fieldOfStudy}${variation.suffix}`
                });
            });
        });
    
        return updatedFields;
    }    

    getEducationTypeAhead(searchText) {
        let searchResults = [];
        searchText = searchText.toLowerCase();

        if (this._searchStringHasDegreesInfoButNoFieldOfStudy(searchText)) {
            return this._getDegreesForSearchString(searchText);
        }

        let searchDegreeObj;
        let preText = "";
        let isSearchStringHasDegrees = false;
        let actualSearchText = "";
        this.allExistingDegrees.forEach(degree => {
            if (searchText.includes(degree.degreeName.toLowerCase()) && !searchText.includes(' or equivalent')) {
                isSearchStringHasDegrees = true;
                preText = degree.degreeName;
                actualSearchText = "";
                actualSearchText = searchText.replace(preText.toLowerCase(), "");
                if (actualSearchText.includes(" in ")) {
                    actualSearchText = actualSearchText.replace(" in ", "");
                } else if (actualSearchText.includes(" in")) {
                    actualSearchText = actualSearchText.replace(" in", "");
                }
                searchDegreeObj = _.cloneDeep(degree);
                isSearchStringHasDegrees = true;
            }
        });

        if (!isSearchStringHasDegrees) {
            actualSearchText = searchText;
        }

        this.educationFieldOfStudy = this.educationFieldOfStudy.length == 0 ? this.setEducationFieldOfStudy() : this.educationFieldOfStudy;

        let validFieldOfStudy = this.educationFieldOfStudy.filter((fieldOfStudy) => {
            return fieldOfStudy.fieldOfStudy.toLowerCase().includes(actualSearchText);
        });

        if (isSearchStringHasDegrees && validFieldOfStudy.length > 0) {
            validFieldOfStudy.forEach(fieldOfStudy => {
                searchResults.push(
                    {
                        displayName: searchDegreeObj.name + fieldOfStudy.fieldOfStudy,
                        name: searchDegreeObj.name + fieldOfStudy.fieldOfStudy,
                        experience: 0,
                        mandatory: false,
                        favorite: false,
                        id: fieldOfStudy.id,
                        degreeName: searchDegreeObj.degreeName,
                        fieldOfStudy: fieldOfStudy.fieldOfStudy
                    });
            });
        } else {
            validFieldOfStudy.forEach(fieldOfStudy => {
                searchResults.push(
                    {
                        displayName: fieldOfStudy.fieldOfStudy,
                        name: fieldOfStudy.fieldOfStudy,
                        experience: 0,
                        mandatory: false,
                        favorite: false,
                        id: fieldOfStudy.id,
                        degreeName: "",
                        fieldOfStudy: fieldOfStudy.fieldOfStudy
                    });
            });
        }
        return searchResults;
    }

    setEducation(education) {
        if (education.displayName.includes(" in ") && !education.displayName.includes("or equivalent experience")) {
            let degreesAndFieldOfStudy = education.displayName.split(" in ");
            education.degreeName = degreesAndFieldOfStudy[0] ? degreesAndFieldOfStudy[0] : "";
            education.fieldOfStudy = degreesAndFieldOfStudy[1] ? degreesAndFieldOfStudy[1] : "";
        } else if (education.displayName.includes(" in") && !education.displayName.includes("or equivalent experience")) {
            let degreesAndFieldOfStudy = education.displayName.split(" in");
            education.degreeName = degreesAndFieldOfStudy[0] ? degreesAndFieldOfStudy[0] : "";
            education.fieldOfStudy = "";
        } else {
            education.degreeName = education.displayName;
            education.fieldOfStudy = "";
        }
    }

    setExperienceRoles() {
        let updatedFields = [];
        const variations = [
            '',
            'in similar role',
            'in a fast-paced environment' ,
            'in a technical environment',
            'in development',
            'in development',
            'in senior management',
            'in senior leadership', 
            'in managerial role', 
            'in leadership capacity', 
            'in managing projects', 'managing teams', 
            'in supervising teams', 
            'in supervising a team', 
            'in managing projects ot teams', 
            'in mid-level management'
        ];
        variations.forEach(variation => {
            updatedFields.push({
                id: `${variation}-id`,
                competency: variation,
                role: variation
            });
        });
        
        this.allExistingFieldOfStudy.forEach(fieldOfStudy => {
            updatedFields.push({
                ...fieldOfStudy,
                competency: `in ${fieldOfStudy.fieldOfStudy}`,
                role: `${fieldOfStudy.fieldOfStudy}`
            });
        });

        this.allExistingRoles.forEach(role => {
            updatedFields.push({
                ...role,
                competency: `as a ${role.role}`
            });
        });
    
        return updatedFields;
    }    

    getExperienceTypeAhead(searchText) {
        let searchResults = [];
        searchText = searchText.toLowerCase();

        if (this._searchStringHasYearsInfoButNoSkill(searchText, 'role')) {
            return this._getYearsForSearchString(searchText, 'role');
        }

        let periodObject;
        let preText = "";
        let isSearchStringHasYears = false;
        let actualSearchText = "";
        let existingTimePeriod = this.timePeriods.filter(timePeriod => (searchText.includes(timePeriod.name.toLowerCase()))).sort((a, b) => b.name.length - a.name.length)[0];
        if(existingTimePeriod) {
            isSearchStringHasYears = true;
            preText = existingTimePeriod.name;
            actualSearchText = "";
            actualSearchText = searchText.replace(preText.toLowerCase(), "");
            periodObject = angular.copy(existingTimePeriod);
        }

        if (!isSearchStringHasYears) {
            actualSearchText = searchText;
        }

        this.experienceRoles = this.experienceRoles.length == 0 ? this.setExperienceRoles() : this.experienceRoles;

        let validRoles = this.experienceRoles.filter((role) => {
            return role.competency.toLowerCase().includes(actualSearchText);
        });

        if (isSearchStringHasYears && validRoles.length > 0) {
            validRoles.forEach(role => {
                searchResults.push(
                    {
                        displayName: preText + role.competency,
                        name: preText + role.competency,
                        experience: periodObject.value,
                        mandatory: false,
                        favorite: false,
                        id: role.role.id,
                        title: role.role,
                        role: role
                    });
            });
        } else if (validRoles.length > 0) {
            validRoles.forEach(role => {
                searchResults.push(
                    {
                        displayName: role.competency,
                        name: role.competency,
                        experience: 0,
                        mandatory: false,
                        favorite: false,
                        id: role.role.id,
                        title: role.role,
                        role: role
                    });
            });
        }
        return searchResults;
    }

    _searchStringHasYearsInfoButNoSkill(searchText, type) {
        let yearsResult = [];
        if (type === 'skill') {
            yearsResult = this.timePeriods.filter((period) => {
                return period.name.toLowerCase().includes(searchText);
            });
        } else {
            yearsResult = this.timePeriods.filter((period) => {
                return period.name.toLowerCase().includes(searchText);
            });
        }
        return yearsResult.length > 0;
    }

    _getYearsForSearchString(searchText, type) {
        let yearsResult = []
        if (type === 'skill') {
            yearsResult = this.timePeriods.filter((period) => {
                return period.name.toLowerCase().includes(searchText);
            });
        } else {
            yearsResult = this.timePeriods.filter((period) => {
                return period.name.toLowerCase().includes(searchText);
            });
        }
        let yearsObjectArray = [];
        angular.forEach(yearsResult, (year, key) => {
            yearsObjectArray.push({ displayName: year.name, name: year.name });
        });
        return yearsObjectArray;
    }

    setExperience(experience) {
        let timePeriodObj = this.timePeriods.filter(timePeriod => (experience.displayName.length > timePeriod.name.length) && (experience.displayName.includes(timePeriod.name))).sort((a, b) => b.name.length - a.name.length)[0];
        let addMonthsText = (timePeriodObj &&  timePeriodObj.value.months > 0) ? this.convertMonthsToYearsText(timePeriodObj.value, '', experience.displayName) : '';
            experience.convertedDisplayName = addMonthsText;
        if (timePeriodObj) {
            let typeAheadValue = timePeriodObj.value;
            experience.fromYear = typeAheadValue.fromYear;
            experience.toYear = typeAheadValue.toYear;
            experience.atleastOrMinOrMax = typeAheadValue.range;
            experience.plusTo = typeAheadValue.plusTo;
            experience.competency = experience.displayName.replace(timePeriodObj, "");
            experience.title = experience.displayName.replace(timePeriodObj, "");
        } else {
            experience.competency = experience.displayName;
            experience.title = experience.displayName;
            experience.fromYear = "";
            experience.toYear = "";
            experience.atleastOrMinOrMax = "";
            experience.plusTo = "";
        }
    }

    setSkills(skill) {
        let timePeriod = this.timePeriods.filter(timePeriod => ((skill.displayName.length > timePeriod.length) && skill.displayName.includes(timePeriod)))[0];
        if (timePeriod) {
            let typeAheadValue = timePeriod.value;
            skill.fromYear = typeAheadValue.fromYear;
            skill.toYear = typeAheadValue.toYear;
            skill.atleastOrMinOrMax = typeAheadValue.range;
            skill.plusTo = typeAheadValue.plusTo;
            skill.competency = skill.displayName.replace(timePeriod, "");
            skill.jdActionWord = skill.jdActionWord;
        } else {
            skill.competency = skill.displayName;
            skill.jdActionWord = "";
            skill.fromYear = ""
            skill.toYear = ""
            skill.atleastOrMinOrMax = ""
            skill.plusTo = "";
        }
    }

    newSection() {
        this.sections.push([new RequisitionSkill('')]);
        this.isRemoveSectionActive = this.sections.length > 1;
    }

    removeSection(index) {
        this.sections.splice(index,1);
        this.isRemoveSectionActive = this.sections.length > 1;
    }

    changeSkillPriority(skill, priority) {
        skill.priority = priority;
    }
}
