<div class="col-xs-12 pt-1 pb-1" *ngIf="!isLoading">
    <div class="main-box clearfix col-xs-12 mb-0">
        <div class="main-box-body clearfix col-xs-12 px-0">
            <div class="col-xs-12 px-0 pl-4 pt-2 mt-4 d-flex justify-content-between align-items-center"
                [ngClass]="{'mb-5': !isSharedReq}">
                <div>
                    <h2 class="requisition-description-heading font-bold">Job Description</h2>
                </div>
                <div class="pull-right pr-4 mt-2" *ngIf="currentJobStatus !== 'DRAFT' && !isSharedReq">
                    <div class="btn-group revert-dropdown">
                        <button type="button" class="btn btn-danger" data-toggle="dropdown">Revert to</button>
                        <button type="button" class="btn btn-danger dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <span class="caret"></span>
                            <span class="sr-only">Toggle Dropdown</span>
                        </button>
                        <ul class="dropdown-menu">
                            <li>
                                <a class="pl-4" (click)="revertToJobDescriptionFile()">
                                    <span class="pl-3">Job description file</span>
                                </a>
                            </li>
                            <li>
                                <a class="pl-4" (click)="revertToSavedChanges()">
                                    <span class="pl-3">Saved changes</span>
                                </a>
                            </li>
                            <li>
                                <a class="pl-4" (click)="revertToOriginalActivatedVersion()">
                                    <span class="pl-3">Original Activated Version</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xs-12 mb-3 pl-4" *ngIf="!isSharedReq">
                <div class="col-xs-12 pl-0">
                    <div class="col-xs-5 pl-0">
                        <!-- job title -->
                        <div class="form-group">
                            <label>Title <em>*</em> : </label>
                            <a *ngIf="currentJobStatus !== 'DRAFT'" class="pl-2" (click)="showJobProfile()" style="color: black; font-size: 100%; font-weight: bold;" tooltip="{{jobTitle}}">
                                <span class="control-label">{{jobTitle}}</span>
                            </a>
                            <i class="fas fa-pencil-alt cursor-pointer" tooltip="Edit Title" (click)="editTitleFlag = !editTitleFlag" *ngIf="isUploadRequisition"></i>
                            <input *ngIf="(currentJobStatus == 'DRAFT') && (editTitleFlag || !isUploadRequisition)" type="text" class="form-control" id="jobTitle" [(ngModel)]="jobTitle"
                                    name="jobTitle" style="border-width: 1px;width: 100%">
                        </div>
                    </div>
                    <div class="col-xs-5">
                        <!-- Location -->
                        <div class="form-group">
                            <label>Location : </label>
                            <span tooltip="{{jobLocation}}" style="font-size: 12px; font-weight: bold; cursor:pointer;">
                                {{jobLocation}}
                            </span>
                            <i class="fas fa-pencil-alt cursor-pointer" tooltip="Edit Location" (click)="updateLocation()"></i>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="col-xs-12 mt-3">
                <div class="col-xs-12 my-2">
                    <!-- Education -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container mr-2 py-4 px-3">
                            <app-add-edit-candidate-skill [skills]="requisitionSkills.educations" skillType="education" context="job"
                            mode="edit" [allExistingFieldOfStudy]="allExistingFieldOfStudy" [allExistingDegrees]="allExistingDegrees" [isViewOnly]="isViewOnly"></app-add-edit-candidate-skill>
                        </div>
                    </div>
                    <!-- Experience -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container ml-3 py-4 px-3">
                            <app-add-edit-candidate-skill [skills]="requisitionSkills.experiences" [allExistingSkills]="allExistingSkills"
                                (saveCallback)="skillsSaveCallback($event)" skillType="experience" context="job"
                                mode="edit" [allExistingRoles]="allExistingRoles" [allExistingFieldOfStudy]="allExistingFieldOfStudy" [timePeriods]="timePeriods"
                                [isViewOnly]="isViewOnly"></app-add-edit-candidate-skill>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 my-3">
                    <!-- Technical skills -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container mr-2 py-4 px-3">
                            <app-add-edit-candidate-skill [skills]="requisitionSkills.technicalSkills" [allExistingSkills]="allExistingSkills"
                                (saveCallback)="skillsSaveCallback($event)" skillType="technical" context="job"
                                mode="edit" [allExistingJDActionWords]="allExistingJDActionWords"
                                [timePeriods]="skillsTimePeriods" (setOtherSkillsCallback)="addSkillsToOther($event, 'technicalSkills')"
                                (setSkillsAsPerCategoryCallback)="addSkillsAsPerCategory($event, 'technicalSkills')"
                                [isViewOnly]="isViewOnly"></app-add-edit-candidate-skill>
                        </div>
                    </div>
                    <!-- Operational Skills -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container ml-3 py-4 px-3">
                            <app-add-edit-candidate-skill [skills]="requisitionSkills.operationalSkills"
                                [allExistingSkills]="allExistingSkills" (saveCallback)="skillsSaveCallback($event)"
                                skillType="operational" context="job" mode="edit"
                                [allExistingJDActionWords]="allExistingJDActionWords"
                                [timePeriods]="skillsTimePeriods" (setOtherSkillsCallback)="addSkillsToOther($event, 'operationalSkills')"
                                (setSkillsAsPerCategoryCallback)="addSkillsAsPerCategory($event, 'operationalSkills')"
                                [isViewOnly]="isViewOnly"></app-add-edit-candidate-skill>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 my-2">
                    <!-- Soft Skills -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container mr-2 py-4 px-3">
                            <app-add-edit-candidate-skill [skills]="requisitionSkills.softSkills" [allExistingSkills]="allExistingSkills"
                                (saveCallback)="skillsSaveCallback($event)" skillType="soft" context="job"
                                mode="edit" [allExistingJDActionWords]="allExistingJDActionWords"
                                [timePeriods]="skillsTimePeriods" (setOtherSkillsCallback)="addSkillsToOther($event, 'softSkills')"
                                (setSkillsAsPerCategoryCallback)="addSkillsAsPerCategory($event, 'softSkills')"
                                [isViewOnly]="isViewOnly"></app-add-edit-candidate-skill>
                        </div>
                    </div>
        
                    <!-- Other Skills -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container ml-3 py-4 px-3">
                            <app-add-edit-candidate-skill [skills]="requisitionSkills.otherSkills" [allExistingSkills]="allExistingSkills"
                                (saveCallback)="skillsSaveCallback($event)" skillType="other" context="job"
                                mode="edit" [allExistingJDActionWords]="allExistingJDActionWords"
                                [timePeriods]="skillsTimePeriods" (setSkillsAsPerCategoryCallback)="addSkillsAsPerCategory($event, 'otherSkills')"
                                [isViewOnly]="isViewOnly"></app-add-edit-candidate-skill>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12 my-3">
                    <!-- Certifications -->
                    <div class="col-xs-12 col-lg-6 px-0">
                        <div class="skill-category-container mr-2 py-4 px-3">
                            <app-add-edit-candidate-skill 
                                [skills]="requisitionSkills.certifications" 
                                skillType="certification" 
                                context="job"
                                mode="edit" 
                                [allExistingCertificates]="allExistingCertificates"
                                [isViewOnly]="isViewOnly"
                                >
                            </app-add-edit-candidate-skill>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-xs-12 pt-4" *ngIf="!isLoading && !isSharedReq">
    <div class="main-box clearfix col-xs-12">
        <div class="main-box-body clearfix col-xs-12 px-4">
            <h2 class="requisition-description-heading my-4 pb-1 font-bold">Benefits</h2>
            <!-- Benefits note -->
            <div class="form-group mb-0">
                <app-ngx-editor [placeholder]="'Enter text here...'" [spellcheck]="true" [(ngModel)]="benefitsNote"
                    [config]="editorConfig">
                </app-ngx-editor>
                <!-- <div class="error error-gap" *ngIf="!benefitsNotes">
                <small class=""><em>*</em>
                    External Note is required
                </small>
            </div> -->
            </div>
        </div>
    </div>
</div>

<div class="main-box-body clearfix col-lg-12" *ngIf="isLoading">
    <div class="text-center" style="font-size: 20px;">
        <span class="pr-2">Loading</span>
        <span class="fa fa-spinner fa-spin"></span>
    </div>
</div>
